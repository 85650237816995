<template lang="pug">

  .home   

    .row.mt-1(v-if="wiki")
        .col-12.col-md-3.mb-5
            b-list-group()
              b-list-group-item(v-for="cat in wiki['pages']" v-if="!cat.hide")
                  .wiki-head {{cat.name}}
                  template(v-for="page in cat.pages", v-if="!page.hide")
                      template(v-if="page.is_folder")
                        a(v-b-toggle="page.id + '-collapse-1'", href="javascript:void(0);").d-block.mt-1.text-muted {{page.name}} 
                          
                            .when-closed.float-right
                                i.fas.fa-caret-down
                                
                            .when-opened.float-right
                                i.fas.fa-caret-up

                        b-collapse(:id="page.id +'-collapse-1'" :visible="page.collapsed")
                          div(v-for="sub_page in page['pages']"  v-if="!sub_page.hide")
                            router-link(:to="'/wiki/w/' + $route.params.id + '/pages/' + sub_page.id").d-block.mt-1.text-muted.ml-2.small {{sub_page.name}}
                        
                      template(v-if="!page.is_folder")
                        router-link(:to="'/wiki/w/' + $route.params.id + '/pages/' + page.id").d-block.mt-1.text-muted {{page.name}}
              
              b-list-group-item()
                a(href="https://hy.ecosystem-manager.co/projects", target="_blank").d-block.text-muted Project database
            
        .col-12.col-md-9(v-if="currentPage")
            b-overlay(:show="$store.state.loading" )
                //iframe(ref="iframe1", style="width: 100%; height: 100%; min-height: 1000px; background-color: #fff", border="0", frameborder="0")
                //| {{'https://docs.google.com/viewer?url=https://docs.google.com/presentation/d/' + currentPage.id + '&embedded=true'}}
                //iframe(:src="'https://docs.google.com/viewer?url=https://docs.google.com/presentation/d/' + currentPage.id + '&embedded=true'")
                template(v-if="currentType=='presentation'")
                    //iframe(:src="'https://docs.google.com/viewer?srcid='+  currentPage.id + '&pid=explorer&a=v&chrome=false&embedded=true'" width="100%" height="1000px")
                    iframe(:src="'https://docs.google.com/presentation/d/'+  currentPage.id + '/preview'" width="100%" height="600px")
                    //1cqjhWAEmTMhdVqaK-hkS_W3MlYh3fyH1UX5Pi94kxZ8
                template(v-if="currentPageContent && currentType=='doc'")
                    div(v-html="currentPageContent", style="min-height: 800px;").bg-white.pl-5.pr-5
                .float-right
                  a.text-muted.small.mt-2(v-if="$route.params.id" :href="'/wiki/w/' + $route.params.id + '/pages/' + $route.params.page + '?refresh=1'") Refetch
                
          
</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
      wiki: null,
      currentPage: null,
      currentType: null,
      currentPageContent: null
    }
  },

   watch: {
    
    '$route.params.page': function () {
      this.openDoc(this.findPage(this.wiki.pages, this.$route.params.page));
    }

  },

  mounted: function() {
    this.getData();
  },

   computed: {
        ...mapFields([
           
        ]),
  },

  methods: {
    
    openDoc(page) {
    this.$store.dispatch("start_loading");
    //this.$refs['iframe1'].src = page.webViewLink;
    //this.currentPage = page;

    //if(page.mimeType == "application/vnd.google-apps.presentation") {
    if(page.mimeType == "application/vnd.google-apps.presentation") {
        this.currentPage = page;

        this.currentType = "presentation";
        this.$store.dispatch("stop_loading");
        // Vue.http.get(Config.config.base_host+"/wiki/"+this.$route.params.id+"/pages/" + page.id+ "/file.pdf").then(resp => {
        //     this.currentPageContent = resp.body;
            
            
        //     let src = 'data:application/pdf;base64,' + 
        //         encodeURIComponent( // Escape for URL formatting
        //             resp.body
        //         );
        //     this.$refs['iframe1'].src = src;
            
        //     console.log(src);
        //     this.$store.dispatch("stop_loading");
        // }); 
    } else {
        this.currentType = "doc";
        Vue.http.get(Config.config.base_host+"/wiki/"+this.$route.params.id+"/pages/" + page.id+ "/file.html" + (this.$route.query.refresh ? "?refresh=1" : "")).then(resp => {
            this.currentPageContent = resp.body;
            this.currentPage = page;
            this.$store.dispatch("stop_loading");
        }); 

        if(this.$route.query.refresh) {
          this.getData(true);
        }
        
    }
  
       
    },

    findPage(pages, _id) {
      
       for(let page of pages) { 
         
         if(page.id == _id) return page;
         if(page.pages) {
           
           let d = this.findPage(page.pages, _id);
           if(d) {
             return d;
           }
          
         }
       }
      
    },

    getData(refresh = false) {
       Vue.http.get(Config.config.base_host+'/wiki/' + this.$route.params.id + (refresh ? "?refresh=1" : "")).then(resp => {
            this.wiki = resp.body;
            
            if(!this.$route.params.page) {
              
              let id = this.wiki.pages[0].pages[0].id;
             
              this.$router.push('/wiki/w/' + this.$route.params.id + '/pages/' + id);

            } else {
              if(this.$route.query.refresh) {
                this.$router.push('/wiki/w/' + this.$route.params.id + '/pages/' + this.$route.params.page);
              }
              
              this.openDoc(this.findPage(this.wiki.pages, this.$route.params.page));
          
            
            }
            
            
       }); 
    },

  },

  components: {

  }

}

</script>
<style lang="scss" scoped>
  
  .wiki-head {
      font-family: "IBM Plex Serif" !important;
      font-weight: 800;
      font-size: 1.25em;
  }
  a { 
      cursor: pointer;
  }

  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
      display: none;
  }

</style>